var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',[_c('b-table',{attrs:{"responsive":"","id":"AgentTable","fields":_vm.fields,"items":_vm.agentList},scopedSlots:_vm._u([{key:"cell(index)",fn:function(data){return [_vm._v(" "+_vm._s(data.index + 1 + _vm.perPage * (_vm.currentPage - 1))+" ")]}},(
        this.$store.state.app.user.permissions.includes('agent.change_agent')
      )?{key:"cell(agent_type_nicename)",fn:function(data){return [_c('b-form-input',{attrs:{"type":"text","disabled":data.index != _vm.editEnable},on:{"input":function($event){_vm.updatedValue = _vm.agentList[data.index].agent_type_nicename}},model:{value:(_vm.agentList[data.index].agent_type_nicename),callback:function ($$v) {_vm.$set(_vm.agentList[data.index], "agent_type_nicename", $$v)},expression:"agentList[data.index].agent_type_nicename"}})]}}:{key:"cell(agent_type_nicename)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.agent_type_nicename)+" ")]}},(
        this.$store.state.app.user.permissions.includes('agent.change_agent')
      )?{key:"cell(credit_per_scan)",fn:function(data){return [_c('b-form-input',{attrs:{"type":"number","onkeypress":"return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))","disabled":data.index != _vm.editEnable},on:{"input":function($event){_vm.updatedCredit = _vm.agentList[data.index].credit_per_scan}},model:{value:(_vm.agentList[data.index].credit_per_scan),callback:function ($$v) {_vm.$set(_vm.agentList[data.index], "credit_per_scan", $$v)},expression:"agentList[data.index].credit_per_scan"}})]}}:(this.updatedLimit == true)?{key:"cell(credit_per_scan)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.credit_per_scan)+" ")]}}:null,(
        this.$store.state.app.user.permissions.includes('agent.change_agent')
      )?{key:"cell(unlimited)",fn:function(data){return [_c('b-form-checkbox',{staticClass:"custom-control-primary ml-1",attrs:{"switch":"","disabled":data.index != _vm.editEnable},on:{"change":function($event){_vm.updateLimit(
            data.item.agent_type_id,
            (_vm.updatedLimit = _vm.agentList[data.index].is_unlimited),
            data.index
          )}},model:{value:(_vm.agentList[data.index].is_unlimited),callback:function ($$v) {_vm.$set(_vm.agentList[data.index], "is_unlimited", $$v)},expression:"agentList[data.index].is_unlimited"}},[_c('span',{staticClass:"switch-icon-left"},[_c('feather-icon',{attrs:{"icon":"CheckIcon"}})],1),_c('span',{staticClass:"switch-icon-right"},[_c('feather-icon',{attrs:{"icon":"XIcon"}})],1)])]}}:{key:"cell(unlimited)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.is_unlimited)+" ")]}},(
        this.$store.state.app.user.permissions.includes('agent.change_agent')
      )?{key:"cell(description)",fn:function(data){return [_c('b-form-input',{attrs:{"type":"text","disabled":data.index != _vm.editEnable || _vm.disableCredit},on:{"input":function($event){_vm.updatedCredit = _vm.agentList[data.index].description}},model:{value:(_vm.agentList[data.index].description),callback:function ($$v) {_vm.$set(_vm.agentList[data.index], "description", $$v)},expression:"agentList[data.index].description"}})]}}:(this.updatedLimit == true)?{key:"cell(description)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.description)+" ")]}}:null,{key:"cell(action)",fn:function(data){return [(_vm.changeAgent)?_c('div',{staticClass:"d-flex"},[(data.index != _vm.editEnable)?_c('b-button',{attrs:{"variant":"outline-warning","size":"sm"},on:{"click":function($event){return _vm.updateAgent(data.index)}}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}}),_vm._v(" Edit ")],1):_c('b-button',{attrs:{"variant":"outline-success","size":"sm"},on:{"click":function($event){_vm.updateAll(
              data.item.agent_type_id,
              (_vm.updatedValue = _vm.agentList[data.index].agent_type_nicename),
              (_vm.updatedCredit = _vm.agentList[data.index].credit_per_scan),
              (_vm.updatedLimit = _vm.agentList[data.index].is_unlimited),
              (_vm.updatedDescription = _vm.agentList[data.index].description)
            )}}},[(_vm.isLoadingAgent)?_c('span',[_c('b-spinner',{attrs:{"type":"border","small":""}}),_vm._v(" Please wait ")],1):_c('span',[_c('feather-icon',{attrs:{"icon":"RefreshCwIcon"}}),_vm._v(" Update ")],1)])],1):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }