<template>
  <b-card-code>
    <!-- {{ agentList }} -->
    <b-table responsive id="AgentTable" :fields="fields" :items="agentList">
      <template #cell(index)="data">
        {{ data.index + 1 + perPage * (currentPage - 1) }}
      </template>
      <template
        v-if="
          this.$store.state.app.user.permissions.includes('agent.change_agent')
        "
        #cell(agent_type_nicename)="data"
      >
        <!-- @blur="updateValue(data.item.agent_type_id, updatedValue)" -->
        <b-form-input
          type="text"
          v-model="agentList[data.index].agent_type_nicename"
          @input="updatedValue = agentList[data.index].agent_type_nicename"
          :disabled="data.index != editEnable"
        ></b-form-input>
      </template>
      <template v-else #cell(agent_type_nicename)="data">
        {{ data.item.agent_type_nicename }}
      </template>
      <template
        v-if="
          this.$store.state.app.user.permissions.includes('agent.change_agent')
        "
        #cell(credit_per_scan)="data"
      >
        <!-- @blur="updateCredit(data.item.agent_type_id, updatedCredit)" -->
        <b-form-input
          type="number"
          onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
          v-model="agentList[data.index].credit_per_scan"
          @input="updatedCredit = agentList[data.index].credit_per_scan"
          :disabled="data.index != editEnable"
        ></b-form-input>
      </template>
      <template
        v-else-if="this.updatedLimit == true"
        #cell(credit_per_scan)="data"
      >
        <!-- <b-form-input
          type="number"
          onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
          v-model="agentList[data.index].credit_per_scan"
          @input="updatedCredit = agentList[data.index].credit_per_scan"
          @blur="updateCredit(data.item.agent_type_id, updatedCredit)"
          disabled
        ></b-form-input> -->
        {{ data.item.credit_per_scan }}
      </template>
      <template
        v-if="
          this.$store.state.app.user.permissions.includes('agent.change_agent')
        "
        #cell(unlimited)="data"
      >
        <!-- <b-form-select
          class=""
          v-model="agentList[data.index].is_unlimited"
          :options="unlimted_options"
          @change="
            updateLimit(
              data.item.agent_type_id,
              (updatedLimit = agentList[data.index].is_unlimited)
            )
          "
        >
        </b-form-select> -->
        <!-- @change="
            updateLimit(
              data.item.agent_type_id,
              (updatedLimit = agentList[data.index].is_unlimited)
            )
          " -->
        <b-form-checkbox
          v-model="agentList[data.index].is_unlimited"
          class="custom-control-primary ml-1"
          switch
          :disabled="data.index != editEnable"
          @change="
            updateLimit(
              data.item.agent_type_id,
              (updatedLimit = agentList[data.index].is_unlimited),
              data.index
            )
          "
        >
          <span class="switch-icon-left">
            <feather-icon icon="CheckIcon" />
          </span>
          <span class="switch-icon-right">
            <feather-icon icon="XIcon" />
          </span>
        </b-form-checkbox>
      </template>
      <template v-else #cell(unlimited)="data">
        {{ data.item.is_unlimited }}
      </template>
      <template
        v-if="
          this.$store.state.app.user.permissions.includes('agent.change_agent')
        "
        #cell(description)="data"
      >
        <!-- @blur="updateCredit(data.item.agent_type_id, updatedCredit)" -->
        <b-form-input
          type="text"
          v-model="agentList[data.index].description"
          @input="updatedCredit = agentList[data.index].description"
          :disabled="data.index != editEnable || disableCredit"
        ></b-form-input>
      </template>
      <template v-else-if="this.updatedLimit == true" #cell(description)="data">
        {{ data.item.description }}
      </template>
      <template #cell(action)="data">
        <div class="d-flex" v-if="changeAgent">
          <b-button
            v-if="data.index != editEnable"
            variant="outline-warning"
            class=""
            size="sm"
            @click="updateAgent(data.index)"
          >
            <feather-icon icon="EditIcon" class="" />
            Edit
          </b-button>
          <b-button
            v-else
            variant="outline-success"
            class=""
            size="sm"
            @click="
              updateAll(
                data.item.agent_type_id,
                (updatedValue = agentList[data.index].agent_type_nicename),
                (updatedCredit = agentList[data.index].credit_per_scan),
                (updatedLimit = agentList[data.index].is_unlimited),
                (updatedDescription = agentList[data.index].description)
              )
            "
          >
            <span v-if="isLoadingAgent"
              ><b-spinner type="border" small></b-spinner> Please wait
            </span>
            <span v-else>
              <feather-icon icon="RefreshCwIcon" class="" />
              Update
            </span>
          </b-button>
        </div>
      </template>
    </b-table>
  </b-card-code>
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { BTable } from "bootstrap-vue";
import {
  BPagination,
  BPaginationNav,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BBadge,
  VBToggle,
  BSidebar,
  BRow,
  BForm,
  BFormInput,
  BFormSelect,
  BSpinner,
  BButton,
  BTab,
  BTabs,
  BFormCheckbox,
} from "bootstrap-vue";

export default {
  components: {
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BBadge,
    BRow,
    BForm,
    BFormInput,
    BFormSelect,

    BSpinner,
    BButton,
    BTab,
    BTabs,
    BFormCheckbox,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      agent_types: [],
      agentList: [],
      fields: [
        { key: "index", label: "#" },
        { key: "agent_type_nicename", label: "Nice Name" },
        { key: "agent_type_code", label: "Agent Type Code" },
        { key: "unlimited", label: "Unlimited Credit" },
        { key: "credit_per_scan", label: "Credit per Scan" },
        { key: "description", label: "Description" },
        { key: "action", label: "Action" },
      ],
      agentId: "",
      updatedValue: "",
      updatedCredit: "",
      updatedLimit: false,
      updatedDescription: "",
      isLoadingAgent: false,
      unlimited: false,
      editEnable: null,
      disableCredit: null,
      changeAgent:
        this.$store.state.app.user.permissions.includes("agent.change_agent"),
    };
  },
  mounted() {
    this.onVerticalCollapse();
  },
  created: function () {
    this.load();
  },
  methods: {
    onVerticalCollapse() {
      let coll = true;
      this.$store.commit("verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED", coll);
    },
    updateLimit(agent_type_id, updatedLimit, index) {
      if (updatedLimit) {
        this.agentList[index].credit_per_scan = 0;
        this.disableCredit = true;
      } else {
        this.disableCredit = false;
      }
    },

    updateAll(
      agent_type_id,
      updatedValue,
      updatedCredit,
      updatedLimit,
      updatedDescription
    ) {
      this.isLoadingAgent = true;
      this.agentId = agent_type_id;
      this.updatedValue = updatedValue;
      this.updatedCredit = updatedCredit;
      this.updatedLimit = updatedLimit;
      this.updatedDescription = updatedDescription;
      var self = this;
      let data = {
        agent_type_nicename: this.updatedValue,
        credit_per_scan: this.updatedCredit,
        is_unlimited: this.updatedLimit,
        description: this.updatedDescription,
      };
      const options = {
        method: "PATCH",
        headers: { "content-type": "application/json" },
        data: data,
        url: process.env.VUE_APP_BASEURL + "agent/type/" + this.agentId + "/",
      };
      this.$http(options)
        .then((res) => {
          console.log(res);
          if (res.data.agent_type_id) {
            this.$router.push({ name: "Agent List" });
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Edited Successfully",
                icon: "EditIcon",
                variant: "success",
              },
            });
          }
          this.editEnable = null;
          this.isLoadingAgent = false;
          this.load();
        })
        .catch((err) => {
          this.isLoadingAgent = false;
          console.log(err);
        });
    },
    load: function () {
      const a_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "agent/type",
      };
      var self = this;
      this.$http(a_options).then((res) => {
        this.agentList = res.data;
      });
    },
    editAgent(id, name) {
      this.$router.push({ name: "Edit Agent", params: { id: id } });
    },
    updateAgent(index) {
      // this.$refs[`nickName${index}`].focus();
      this.editEnable = index;
    },
  },
};
</script>
<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
